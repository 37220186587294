@import '~antd/dist/antd.less';

@font-face {
  font-family: "Menlo";
  src: url("./assets/fonts/Menlo-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Menlo";
  src: url("./assets/fonts/Menlo-Regular.woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Manrope";
  src: url("./assets/fonts/Manrope-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Manrope";
  src: url("./assets/fonts/Manrope-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Manrope";
  src: url("./assets/fonts/Manrope-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Manrope";
  src: url("./assets/fonts/Manrope-ExtraBold.ttf");
  font-weight: 800;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "Manrope", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Manrope", sans-serif !important;
}

.sidebar #sidebarToggle::after {
  content: "";
}

.sidebar.toggled #sidebarToggle::after {
  content: "";
  margin-left: 0;
}

html {
  scroll-behavior: smooth;
}

span,
p,
td,
tr,
div,
i {
}
h1,
h2,
h3,
h4,
h5,
h6,
strong {
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #404040 !important;
}
.sidebar {
  position: fixed;
  height: 100vh !important;
}

.nav-link,
a {
  cursor: pointer;
}

.fixed-width {
  width: 250px !important;
}

.sidebar .nav-item .collapse {
  margin: 1rem !important;
}

.pagination {
  float: right;
}

.bg-gradient-primary {
  background-color: transparent !important;
  background-image: linear-gradient(
    180deg,
    transparent 10%,
    transparent 100%
  ) !important;
  background-size: cover;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2);
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item.active,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item.active {
  color: #2b64f5 !important;
  font-weight: 700;
}

.sidebar .nav-item .collapse .collapse-inner {
  background-color: #f2f2f2 !important;
}

.sidebar-light .nav-item .nav-link i {
  color: #474747 !important;
}

.sidebar-light .nav-item .nav-link {
  color: #474747 !important;
}

.sidebar-light .nav-item.active .nav-link i {
  color: #2b64f5 !important;
}

.popover {
  z-index: 100 !important;
}

.sidebar-light .nav-item.active .nav-link {
  color: #2b64f5 !important;
}
.sidebar-light .nav-item .collapse-item.active i {
  color: #2b64f5 !important;
}

a:hover {
  /* color: #f7a630 !important; */
}

a {
  /* color: #f16630 !important; */
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item {
  color: #3a3b45 !important;
}

.navbar-expand-lg .navbar-nav {
  align-items: center;
}

.switch-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

input[type="checkbox"].switch {
  position: absolute;
  opacity: 0;
}

input[type="checkbox"].switch + div {
  vertical-align: middle;
  width: 40px;
  height: 20px;
  border-radius: 999px;
  background-color: #00b0f2;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  cursor: pointer;
}

input[type="checkbox"].switch + div span {
  position: absolute;
  font-size: 1.6rem;
  color: white;
  margin-top: 12px;
}

input[type="checkbox"].switch + div span:nth-child(1) {
  margin-left: 15px;
}

input[type="checkbox"].switch + div span:nth-child(2) {
  margin-left: 55px;
}

input[type="checkbox"].switch:checked + div {
  width: 94px;
  background-position: 0 0;
  background-color: #3b89ec;
}

input[type="checkbox"].switch + div {
  width: 94px;
  height: 48px;
}

input[type="checkbox"].switch:checked + div {
  background-color: #57bb00;
}

input[type="checkbox"].switch + div > div {
  float: left;
  width: 46px;
  height: 46px;
  border-radius: inherit;
  background: #ffffff;
  -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: transform, background-color;
  transition-property: transform, background-color;
  pointer-events: none;
  margin-top: 1px;
  margin-left: 1px;
}

input[type="checkbox"].switch:checked + div > div {
  -webkit-transform: translate3d(20px, 0, 0);
  transform: translate3d(20px, 0, 0);
  background-color: #ffffff;
}

input[type="checkbox"].bigswitch.switch + div > div {
  width: 44px;
  height: 44px;
  margin-top: 1px;
}

input[type="checkbox"].switch:checked + div > div {
  -webkit-transform: translate3d(46px, 0, 0);
  transform: translate3d(46px, 0, 0);
}

.react_times_button {
  box-shadow: none !important;
  border: 1px solid #9997 !important;
  border-radius: 50px !important;
}

.modal {
  direction: ltr;
}

.time_picker_modal_header {
  flex: 1;
  width: 100%;
}

.modal_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item.active,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item.active {
  font-size: 1.1rem;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item {
  font-size: 1.1rem;
  font-weight: bold;
}
.sidebar-light .collapse-item i {
  font-size: 1.1rem !important;
  margin-right: 10px !important;
}

/*--- SCROLL BAR ---*/
/* width */
::-webkit-scrollbar {
  width: 14px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ececec !important;
  cursor: pointer !important;
  border: 3px solid white !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

/* Number Input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@primary-color: #02862F;@success-color: @primary-color;@error-color: #E42326;@border-radius-base: 4px;@select-item-selected-bg: #D6EBDD;@select-item-selected-color: @primary-color;@select-selection-item-bg: #D6EBDD;@tag-border-radius: @border-radius-base;@link-hover-color: @primary-color;@link-hover-decoration: none;@link-focus-decoration: none;@picker-basic-cell-active-with-range-color: #78BE90;@picker-basic-cell-hover-with-range-color: #78BE90;@card-radius: @border-radius-base;@pagination-item-bg-active: @primary-color;@table-font-size: 13px;@tag-line-height: 23px;